import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';


const httpOptions = {
  headers: new HttpHeaders({ 
  'Content-Type': 'application/json', 
 })
};     

@Injectable({
  providedIn: 'root'
})
export class CarsService {
  session:any;
  url       = 'https://redtec.populusinsurtech.technology/api/v1/';

  
  
  constructor(private http: HttpClient, private router: Router) { }


  getMarcas(){
    const httpOptions = {
      headers: new HttpHeaders({       
      'Content-Type': 'application/json', 
      })
    };    
    return this.http.get(this.url + 'marcas', httpOptions);
  }

  getModelos(id_marca){
    const httpOptions = {
      headers: new HttpHeaders({       
      'Content-Type': 'application/json', 
      })
    };
    console.log(this.url + 'modelos/' + id_marca)    
    return this.http.get(this.url + 'modelos/' + id_marca, httpOptions);
  }

  getAnios(id_marca, id_modelo){
    const httpOptions = {
      headers: new HttpHeaders({       
      'Content-Type': 'application/json', 
      })
    };
    console.log(this.url + 'anios/' + id_marca + '/' + id_modelo)    
    return this.http.get(this.url + 'anios/' + id_marca + '/' + id_modelo, httpOptions);
  }

  getBodyType(anio, id_marca, id_modelo){
    const httpOptions = {
      headers: new HttpHeaders({       
      'Content-Type': 'application/json', 
      })
    };
    console.log(this.url + 'bodytype/' + anio + '/' + id_marca + '/' + id_modelo)    
    return this.http.get(this.url + 'bodytype/' + anio + '/' + id_marca + '/' + id_modelo, httpOptions);
  }

  getImagePort(anio, id_marca, id_modelo, id_body){
    const httpOptions = {
      headers: new HttpHeaders({       
      'Content-Type': 'application/json', 
      })
    };
    console.log(this.url + 'port_location/' + anio + '/' + id_marca + '/' + id_modelo + '/' + id_body)    
    return this.http.get(this.url + 'port_location/' + anio + '/' + id_marca + '/' + id_modelo + '/' + id_body, httpOptions);
  }
}
