import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ObdComponent } from './components/obd/obd.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  { path: '' , component: HomeComponent },
  { path: 'obd' , component: ObdComponent },
  { path: '**',
  redirectTo: '',
  pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
