import { Component, OnInit } from '@angular/core';
import { CarsService } from 'src/app/services/cars.service';

@Component({
  selector: 'app-obd',
  templateUrl: './obd.component.html',
  styleUrls: ['./obd.component.css']
})
export class ObdComponent implements OnInit {

  makers: any[];
  models: any[];
  years: any[];
  bodytypes: any[];
  ports: any[] = [];
  maker_select: any = 0;
  model_select: any = 0;
  anio_select: any = 0;
  body_select: any = 0;
  img_select: any = 0;
  id_marca: any;
  id_modelo: any;
  opcionSeleccionado: string = '0';
  verSeleccion: string = '';
  logoMaker = '';

  constructor(public carsService: CarsService) { }

  ngOnInit() {
    this.getmarcas();
  }

  getmarcas() {
    this.carsService.getMarcas().subscribe(
      (marcas: any) => {
        console.log(marcas)
        this.makers = marcas.data
      })
  }

  cleanFilters() {
    this.model_select = 0;
    this.anio_select = 0;
    this.body_select = 0;
    this.ports = [];
  }

  Change() {
    this.cleanFilters();
    localStorage.setItem('maker_selected', this.maker_select.descripcion);
    this.logoMaker = localStorage.getItem('maker_selected');
    console.log('maker', this.logoMaker);
    this.getModelos();
  }

  getModelos() {
    this.model_select = 0
    this.carsService.getModelos(this.maker_select.identificador).subscribe(
      (modelos: any) => {
        console.log(modelos)
        this.models = modelos.data
      })
  }

  ChangeModel() {
    this.anio_select = 0
    console.log(this.model_select)
    this.getAnios();
  }

  getAnios() {
    this.body_select = 0
    this.carsService.getAnios(this.maker_select.identificador, this.model_select.identmodel_m).subscribe(
      (anios: any) => {
        console.log(anios)
        this.years = anios.data
      })
  }

  ChangeAnio() {
    console.log(this.anio_select)
    this.getBodyType();
  }

  getBodyType() {
    this.carsService.getBodyType(this.anio_select.anio, this.maker_select.identificador, this.model_select.identmodel_m).subscribe(
      (body: any) => {
        console.log(body)
        this.bodytypes = body.data
      })
  }

  ChangeBody() {
    this.body_select
    console.log(this.body_select)
    this.getImagePort();
  }

  getImagePort() {
    this.carsService.getImagePort(this.anio_select.anio, this.maker_select.identificador, this.model_select.identmodel_m
      , this.body_select.ident_tipo).subscribe(
      (port: any) => {
        console.log('ports', port)
        this.ports = [];
        this.ports = port.data
        this.makers = [];
        this.models = [];
        this.years = [];
        this.bodytypes = [];
        this.getmarcas();
        this.maker_select = 0;
      });
  }

}
